class Bridge {
  // Previously sent messages stored for debugging or testing
  static messageHistory = []
  static storeMessageHistory = false

  // Toggles navbar visibility in browser from Turbo Native
  static toggleNavBar() {
    const event = new CustomEvent("toggle-nav-bar")
    window.dispatchEvent(event)
  }

  // Sets the notification count on the app icon and currently visible tab.
  // Passing 0 will remove/clear the badge. Passing null will have no effect.
  static setNotificationCount(icon, tab = icon) {
    this.postMessage("showNotificationBadge", { icon, tab })
  }

  // Sends a message to the native app, if active.
  static postMessage(name, data = {}) {
    if (this.storeMessageHistory) {
      this.messageHistory.push({ name, ...data })
    }
    // iOS
    window.webkit?.messageHandlers?.nativeApp?.postMessage({ name, ...data })

    // Android
    window.nativeApp?.postMessage(JSON.stringify({ name, ...data }))
  }

  static biometricSigninStateChanged(state) {
    document.cookie = `mobile_biometric_signin=${state | 'blocked'}; SameSite=Strict`
    const event = new CustomEvent("biometric-signin-state", { detail: { state } })
    window.dispatchEvent(event)
  }
}

// Expose this on the window object so TurboNative can interact with it
window.TurboNativeBridge = Bridge
export default Bridge
